<template>
  <div class="import-dialog">
    <button
      type="button"
      class="next-btn next-medium next-btn-primary"
      style="margin-right: 8px"
      @click="uploadBtn"
    >
      <span class="next-btn-helper"> {{ btnName }}</span>
    </button>
    <el-dialog
      :width="width"
      :visible.sync="dialogVisible"
      center
      :close-on-click-modal="false"
      :top="top"
      @closed="close"
    >
      <div slot="title" class="dialog-title">{{ importStatusText }}</div>
      <div class="import-dialog-body">
        <!-- 导入步骤 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :class="importStatus == 1 ? 'redColor' : ''">数据导入</el-breadcrumb-item>
          <el-breadcrumb-item :class="importStatus == 2 ? 'redColor' : ''">数据检查</el-breadcrumb-item>
          <!-- <el-breadcrumb-item :class="importStatus == 3 ? 'redColor' : ''">导入</el-breadcrumb-item>
          <el-breadcrumb-item :class="importStatus == 4 ? 'redColor' : ''">完成</el-breadcrumb-item> -->
        </el-breadcrumb>
        <div class="import-step-content" style="padding-top:15px">
          <!-- 第一步 上传 -->
          <div v-if="importStatus == 1" class="import-upload">
            <el-upload
              ref="importUpload"
              action="upload"
              accept=".xlsx"
              drag
              :http-request="addAttachment"
              :on-change="fileChange"
              :file-list="arrUpload"
              :before-remove="deletefile"
              :auto-upload="false"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                将文件拖到此处，或<em> 点击上传</em>
              </div>
              <div slot="tip" class="el-upload__tip">
                只能上传xlsx文件
                <el-link type="primary" :underline="false" @click="downloadTemplate"> 下载Excel模板 </el-link>
                <a v-if="type === 1" ref="downloadTemplate" style="display: none" href="main_users_import.xlsx" />
                <a v-if="type === 2" ref="downloadTemplate" style="display: none" href="sub_users_import.xlsx" />
              </div>
            </el-upload>
          </div>
          <!-- 第二部 检查数据 第三部 导入-->
          <div
            v-if="importStatus == 2 || importStatus == 3"
            class="import-data"
          >
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
              <el-tab-pane
                label="错误数据"
                name="all"
              />
            </el-tabs>
            <el-table
              v-loading="loading"
              element-loading-text="拼命加载中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 255, 255, 0.8)"
              :data="tableData"
              max-height="480"
            >
              <el-table-column
                header-align="center"
                align="center"
                prop="row"
                label="错误行数" />
              <el-table-column prop="date" label="用户登录名称/显示名称" min-width="130">
                <template slot-scope="{ row }">
                  {{ row.name }}
                  <p v-if="row.display_name">{{ row.display_name }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="domain_name" label="所属域" />
              <el-table-column
                v-for="(value, key, index) in tableColumnName"
                :key="key"
                :index="index"
                :prop="'cell' + key"
                :label="value"
                show-overflow-tooltip
              />
              <el-table-column
                label="状态"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-tag
                    :type="scope.row.status == 'Y' ? 'success' : 'danger'"
                    effect="dark"
                    size="mini"
                  >{{ formatterStatus(scope.row) }}</el-tag
                  >
                </template>
              </el-table-column>
              <el-table-column
                v-if="importStatus == 2"
                prop="msg"
                label="错误原因"
                fixed="right"
              >
                <template slot-scope="{row}">
                  <span>{{ row.msg }}</span>
                </template>
              </el-table-column>
            </el-table>
            <!-- <pagination
              :total="total"
              :page-sizes="[5, 10]"
              :init-size="pageSize"
              :init-current="pageNumber"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            /> -->
          </div>
          <!-- 第四步 完成 -->
          <div v-if="importStatus == 4" class="import-success">
            <el-link class="fontSize40" :underline="false">导入完成</el-link>
            <el-link
              class="fontSize24"
              icon="el-icon-upload2"
              :underline="false"
            >
              本次共上传{{ importData.total }}条数据
              >
              <el-link
                class="fontSize24"
                type="success"
                icon="el-icon-check"
                :underline="false"
              >
                其中校验合格数据{{ importData.correct ? importData.correct : 0 }}条</el-link
              >
              <el-link
                class="fontSize24"
                type="danger"
                icon="el-icon-close"
                :underline="false"
              >
                不合格数据{{ importData.error ? importData.error : 0 }}条</el-link
              >
            </el-link></div>
        </div>
      </div>
      <div slot="footer">
        <el-button
          v-if="importStatus == 2"
          type="warning"
          size="small"
          @click="previousStep"
        >上一步</el-button
        >
        <el-button
          v-if="importStatus ==1"
          type="primary"
          size="small"
          :loading="loadingBtn"
          @click="nextStep"
        >下一步</el-button
        >
        <el-button
          v-if="importStatus == 2"
          type="primary"
          size="small"
          @click="complete"
        >完成</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'
import { user_import } from '@/api'
export default {
  name: 'ImportDialog',
  components: {
    // Pagination
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    type: Number,
    // eslint-disable-next-line vue/require-default-prop
    cssClass: String, // 按钮type
    // eslint-disable-next-line vue/require-default-prop
    size: String, // 按钮大小
    // eslint-disable-next-line vue/require-default-prop
    id: [String, Number],
    btnName: {
      type: String,
      default: '导入'
    }
  },
  data () {
    return {
      dialogVisible: false, // 显示导入弹出框
      width: '850px', // 弹出框宽度
      top: '15vh', // 弹出框高度
      importStatus: 1, // 导入状态
      arrUpload: [], // 上传列表
      fileData: null, // 文件信息
      taskInstanceId: '', // 批次id
      tableData: [], // 表格数据
      tableColumnName: [], // 表格列标题
      loading: false, // 数据加载
      total: 0, // 分页总数
      pageSize: 10, // 分页显示个数
      pageNumber: 1, // 页面
      activeName: 'all', // 数据选项卡 success正确数据，error错误数据，all所有数据
      taskName: '', // 数据选项卡名字
      loadingBtn: false, // 按钮加载
      importData: {}, // 导入数据
      labelTips: '' // 错误原因列提示
    }
  },
  computed: {
    importStatusText () {
      let str = ''
      switch (this.importStatus) {
        case 1:
          str = '数据导入'
          break
        case 2:
          str = '数据检查'
          break
        case 3:
          str = '导入'
          break
        case 4:
          str = '完成'
          break
        default:
          str = '导入'
      }
      return str
    },

    buttonClass () {
      return this.cssClass || 'primary'
    },
    href () {
      return '.'
    }
  },
  methods: {
    downloadTemplate () {
      this.$refs.downloadTemplate.dispatchEvent(new MouseEvent('click'))
    },
    uploadBtn () {
      this.dialogVisible = true
      this.$emit('uploadClick')
    },
    // 选择文件
    addAttachment (file) {
      console.log(file)
      // 保存一份文件信息，用于上传
      this.fileData = file.file
    },
    // 上传文件
    upload () {
      this.loadingBtn = true
      if (!(this.fileData === null)) {
        const formData = new FormData()
        formData.append('excelfile', this.fileData)
        formData.append('account_type', this.type)

        user_import(formData).then(res => {
          this.loadingBtn = false
          if (res.res == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'success'
            })
            this.dialogVisible = false
          } else {
            this.$message.error(res.msg)
            this.importStatus = 2
            this.tableData = res.data
          }
          this.$emit('upload')
        }).catch((error) => {
          this.loadingBtn = false
          this.$message.error('上传失败,稍后重试~')
          console.log(error)
        })
        this.$api.importApi
          .upload(formData, this.id)
          .then((res) => {
          // console.log(res)
            if (!(res.status == 'false')) {
              this.taskInstanceId = res.taskInstanceId
              this.taskName = res.taskName
              // 数据检查
              this.dataCheck()
              this.importStatus++
              this.loadingBtn = false
              this.width = '1200px'
              this.top = '5vh'
            } else {
              this.loadingBtn = false
              this.$message.error('上传失败,请重试~')
              this.$message.error(res.msg)
            }
          })
          .catch((error) => {
            this.loadingBtn = false
            this.$message.error('上传失败,稍后重试~')
            console.log(error)
          })
      } else {
        this.loadingBtn = false
        this.$message({
          message: '文件不能为空~',
          type: 'warning'
        })
      }
    },
    // 上传文件改变时候触发的方法
    fileChange (file) {
      // console.log(file)
      const Xls = file.name.split('.')
      const isLt1M = file.size / 1024 / 1024 < 1
      if (Xls[1] === 'xlsx') {
        if (isLt1M) {
          this.$refs.importUpload.clearFiles() // 清除文件对象
          this.fileData = file.raw // 取出上传文件的对象，在其它地方也可以使用
          // 重新手动赋值filstList， 免得自定义上传成功了, 而fileList并没有动态改变， 这样每次都是上传一个对象
          this.arrUpload = [{ name: file.name, url: file.url }]
        } else {
          this.$message.error('上传文件大小不能超过 1MB!')
          this.arrUpload = []
          return false
        }
      } else {
        this.$message.error('上传文件只能是 xlsx 格式!')
        this.arrUpload = []
        return false
      }
    },
    // 删除上传文件
    deletefile (file, fileList) {
      this.$refs.importUpload.clearFiles() // 清除文件对象
      this.fileData = null
      this.arrUpload = []
    },
    // 上一步
    previousStep: function () {
      if (this.importStatus == 3) {
        this.pageSize = 10 // 分页显示个数
        this.pageNumber = 1 // 页面
        this.activeName = 'all'
        this.dataCheck()
      } else {
        this.width = '850px'
        this.top = '15vh'
      }
      this.importStatus--
    },
    // 下一步
    nextStep () {
      if (this.importStatus == 1) {
        this.fristStep()
      } else if (this.importStatus == 2) {
        this.secondStep()
      } else if (this.importStatus == 3) {
        this.thirdStep()
      }
    },
    fristStep: function () {
      this.upload()
      this.activeName = 'all'
      // 错误原因列提示
      this.labelTips = this.id == 4 ? '检查结果' : '错误原因'
      // 获取列名称
      this.$api.importApi
        .tableColumnName({ id: this.id })
        .then((res) => {
          // console.log(res)
          this.tableColumnName = res.newcell[0]
        })
        .catch((error) => {
          console.log(error)
        })
    },
    secondStep: function () {
      this.pageSize = 10 // 分页显示个数
      this.pageNumber = 1 // 页面
      this.activeName = 'success'
      this.dataCheck('Y')
      this.importStatus++
    },
    thirdStep: function () {
      // 导入
      this.loadingBtn = true
      const data = {
        taskId: this.id, // 任务id
        id: this.taskInstanceId // 批次id
      }
      this.$api.importApi
        .importSuccess(data)
        .then((res) => {
          // console.log(res)
          this.importData = res
          this.importStatus++
          this.loadingBtn = false
          this.width = '850px'
          this.top = '15vh'
        })
        .catch((error) => {
          this.loadingBtn = false
          console.log(error)
        })
    },
    // 导出完成
    complete: function () {
      this.dialogVisible = false
    },
    close: function () {
      // 刷新表格
      if (this.importStatus == 4) {
        this.$emit('refresh-table')
      }
      this.importStatus = 1
      this.width = '850px'
      this.top = '15vh'
      this.arrUpload = [] // 上传列表
      this.fileData = null // 文件信息
      this.tableData = [] // 表格数据
      this.tableColumnName = [] // 表格列标题
      this.loading = false
      this.pageSize = 10 // 分页显示个数
      this.pageNumber = 1 // 页面
      this.activeName = 'all' // 数据选项卡 success正确数据，error错误数据，all所有数据
      this.taskName = '' // 数据选项卡名字
    },
    // 数据检查
    dataCheck (param) {
      this.loading = true
      const data = {
        pageSize: this.pageSize,
        pageNum: this.pageNumber,
        status: param,
        taskInstanceId: this.taskInstanceId
      }
      this.$api.importApi
        .importDataList(data)
        .then((res) => {
          // console.log(res)
          this.tableData = res.content
          this.total = res.total
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    // 切换查看检查数据
    handleClick (tab, event) {
      this.pageSize = 10 // 分页显示个数
      this.pageNumber = 1 // 页面
      if (tab.name == 'error') {
        this.dataCheck('N')
      } else {
        this.dataCheck()
      }
    },
    // 点击分页
    handleCurrentChange (val) {
      this.pageNumber = val
      let type = ''
      if (this.activeName == 'error') {
        type = 'N'
      } else if (this.activeName == 'success') {
        type = 'Y'
      } else {
        type = ''
      }
      this.dataCheck(type)
    },
    // pageSize 改变时会触发
    handleSizeChange (val) {
      this.pageNumber = 1
      this.pageSize = val
      let type = ''
      if (this.activeName == 'error') {
        type = 'N'
      } else if (this.activeName == 'success') {
        type = 'Y'
      } else {
        type = ''
      }
      this.dataCheck(type)
    },
    // 格式化状态
    formatterStatus (row) {
      return row.status == 'Y' ? '成功' : '失败'
    },
    // 格式化错误原因
    formatterCheckResult (row) {
      const { checkResult } = row
      if (!checkResult) {
        return '--'
      }
      return row.checkResult.replace(/；/g, '<br/>')
    }
  }
}
</script>

<style lang="less">
.import-dialog {
  display: inline;
  .dialog-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }
  .import-dialog-body {
    .redColor {
      .el-breadcrumb__inner {
        color: #f56c6c;
      }
    }
    .import-step-content {
      width: 100%;
      text-align: center;
      margin-top: 10px;
      .el-upload__tip {
        display: flex;
        align-items: center;
      }
    }
    .import-success {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .fontSize40 {
      width: 300px;
      font-size: 40px;
    }
    .fontSize24 {
      width: 300px;
      font-size: 24px;
      justify-content: left;
    }
  }
}
.new-toolNumber + .import-dialog,
.new-toolNumber + .el-button,
.el-button + .import-dialog,
.import-dialog + .el-button,
.group-dialog + .import-dialog,
.group-dialog + .el-button {
  margin-left: 10px;
}
.el-dialog__header {
  padding: 15px !important;
  border-bottom: 1px solid #dcdfe6;
}
</style>
